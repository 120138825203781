import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Divider,
  Typography,
  Grid2,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";
import { handleExportToWordBlobLOI } from "../../services/loiDocxService";
import { handleSaveLOIAsPDF } from "../../services/loiPdfService";
import { sendFieldFeedback } from "../../services/feedbackService";
import { UserContext } from "../../context/UserContext";
import { getTemplateFunctions } from "../../templates/utils";
import { contentStyle } from "../../styles/templateStyles";
import PropTypes from "prop-types";
import EditableFieldTemplate from "./EditableFieldTemplate";
import GenerationCard from "./GenerationCard";
import ExportButtons from "./ExportButtons";
import Feedback from "../common/Feedback";
import { saveAs } from "file-saver";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import PDFViewer from "../common/PDFViewer";
import ShipsterCard from "../common/ShipsterCard";
import PreviewWrapper from "./PreviewWrapper";

const Indemnity = forwardRef(
  ({ details, staticData, requestId, multiple }, ref) => {
    const [trackedChanges, setTrackedChanges] = useState({});
    const { user } = useContext(UserContext);
    const [template, setTemplate] = useState(null);
    const [isEditable, setIsEditable] = useState(
      !multiple.isMultipleGeneration
    );
    const theme = useTheme();

    const strongStyle = {
      color: theme.palette.semantic.success,
      fontWeight: "bold",
      textDecoration: "underline",
      textDecorationStyle: "dotted",
    };

    // Helper function to get the value or return "MISSING"
    const getValue = (key) => {
      const value = details[key]?.value;
      return value || "MISSING";
    };

    const toggleEdit = () => {
      setIsEditable(!isEditable);
    };

    const determineDeliveryParty = (deliveryParty, consigneeDetails) => {
      if (deliveryParty) {
        if (
          consigneeDetails.includes(deliveryParty) &&
          consigneeDetails.length > deliveryParty.length
        ) {
          return consigneeDetails;
        }
        return deliveryParty;
      }
      return consigneeDetails;
    };

    // Function to add or update tracked changes with document IDs
    const trackChange = (field, newValue) => {
      setTrackedChanges((prevChanges) => {
        const originalValue = formData[field];
        // Only add to trackedChanges if the value is actually changed
        if (originalValue !== newValue) {
          return {
            ...prevChanges,
            [field]: { value: newValue },
          };
        } else {
          // Remove from trackedChanges if it reverts back to original
          // eslint-disable-next-line no-unused-vars
          const { [field]: _, ...remainingChanges } = prevChanges;
          return remainingChanges;
        }
      });
    };

    const sendTrackChangesToBackend = async () => {
      try {
        await sendFieldFeedback(
          getOrganisationFromUser(user),
          requestId,
          trackedChanges,
          "MR"
        );
      } catch (error) {
        console.log(error);
      }
    };

    const handleSaveAsWordLOI = async () => {
      try {
        sendTrackChangesToBackend();
        const wordDocBlob = await handleExportToWordBlobLOI(formData);
        saveAs(
          wordDocBlob,
          `LOI_${formData.owners}_${formData.loiTemplate.type}.docx`
        );
      } catch (error) {
        console.error("Error generating Word document:", error);
      }
    };

    const handleSaveAsPDFLOI = async () => {
      sendTrackChangesToBackend();
      handleSaveLOIAsPDF(formData);
    };

    useImperativeHandle(ref, () => ({
      handleSaveAsWordLOI,
      handleSaveAsPDFLOI,
    }));

    const [formData, setFormData] = useState({
      currentDate: staticData.currentDate,
      vessel: getValue("vessel_name"),
      owners: getValue("owners_managers"),
      ownersManagers: staticData.owners
        ? staticData.owners
        : getValue("owners_managers"),
      portLoading: getValue("port_of_loading"),
      portDischarge: getValue("port_of_discharge"),
      cargo: getValue("cargo_description"),
      billOfLadingPlaceAndDateOfIssue: getValue(
        "bill_of_lading_place_and_date_of_issue"
      ),
      billOfLadingIdentificationNumber: getValue(
        "bill_of_lading_identification_number"
      ),
      consignee: getValue("consignee_details"),
      shipper: getValue("shipper_details"),
      deliveryParty: determineDeliveryParty(
        getValue("delivery_party"),
        getValue("consignee_details")
      ),
      requestorParty: staticData.requestorCompany,
      requestorName: staticData.requestorName,
      requestorDesignation: staticData.requestorDesignation,
      deliveryPlace:
        getValue("delivery_place") === "MISSING"
          ? getValue("port_of_discharge")
          : getValue("delivery_place"),
      address: staticData.address,
      letterhead: staticData.letterheadImage,
      loiTemplate: staticData.loiTemplate,
      missingValue: getValue("missing_value"),
    });

    // useEffect to update formData.letterhead whenever letterheadImage changes
    useEffect(() => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        letterhead: staticData.letterheadImage,
        loiTemplate: staticData.loiTemplate,
      }));
    }, [staticData.letterheadImage, staticData.loiTemplate]);

    // useEffect to update template whenever loiTemplate changes
    // separate to prevent infinite rerenders
    useEffect(() => {
      if (!staticData.loiTemplate) return;

      // Gets the template based on the template type
      const newTemplate = getTemplateFunctions[staticData.loiTemplate.type]({
        shipper: formData.shipper,
        consignee: formData.consignee,
        requestorParty: formData.requestorParty,
        deliveryParty: formData.deliveryParty,
        deliveryPlace: formData.deliveryPlace,
        requestorName: formData.requestorName,
        requestorDesignation: formData.requestorDesignation,
      });

      setTemplate(newTemplate);
    }, [formData, staticData.letterheadImage, staticData.loiTemplate]);

    const handleCopyToClipboard = () => {
      const element = document.getElementById(`text-content-${details.doc_id}`);
      const textToCopy = element.innerText || element.textContent; // Get only the text content
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
      sendTrackChangesToBackend();
    };

    // Function to update formData for simple fields
    const updateFormData = (key, value) => {
      setFormData((prevData) => {
        // Track changes before updating formData (no document ID for simple fields)
        trackChange(key, value);
        return {
          ...prevData,
          [key]: value,
        };
      });
    };

    // Function to render text template
    const renderTextTemplate = (value, contextKey, nullContext, valueName) => {
      const valueDisplay =
        value === "MISSING" ? `MISSING ${nullContext}` : value;
      return (
        <EditableFieldTemplate
          value={valueDisplay}
          context={details[contextKey]?.context}
          nullContext={nullContext}
          confidence={details[contextKey]?.confidence}
          updateFormData={updateFormData}
          valueName={valueName}
          isEditable={isEditable}
        />
      );
    };

    const vesselTemplate = renderTextTemplate(
      formData.vessel,
      "vessel_name",
      "Vessel Name",
      "vessel"
    );
    const portLoadingTemplate = renderTextTemplate(
      formData.portLoading,
      "port_of_loading",
      "Port of Loading",
      "portLoading"
    );
    const portDischargeTemplate = renderTextTemplate(
      formData.portDischarge,
      "port_of_discharge",
      "Port of Discharge",
      "portDischarge"
    );
    const addressTemplate = renderTextTemplate(
      formData.address,
      "address",
      "Address",
      "address"
    );
    const cargoDescriptionTemplate = renderTextTemplate(
      formData.cargo,
      "cargo_description",
      "Cargo Description",
      "cargo"
    );
    const billOfLadingIdentificationNumberTemplate = renderTextTemplate(
      formData.billOfLadingIdentificationNumber,
      "bill_of_lading_identification_number",
      "Bill of Lading Identification Number",
      "billOfLadingIdentificationNumber"
    );
    const billOfLadingPlaceAndDateOfIssueTemplate = renderTextTemplate(
      formData.billOfLadingPlaceAndDateOfIssue,
      "bill_of_lading_place_and_date_of_issue",
      "Place and Date of Issue in the Bill of Lading",
      "billOfLadingPlaceAndDateOfIssue"
    );

    const shipperTemplate = renderTextTemplate(
      formData.shipper,
      "shipper_details",
      "Shipper Name",
      "shipper"
    );
    const consigneeTemplate = renderTextTemplate(
      formData.consignee,
      "consignee_details",
      "Consignee Name",
      "consignee"
    );
    const deliveryPlaceTemplate = renderTextTemplate(
      formData.deliveryPlace,
      "delivery_place",
      "Delivery Place",
      "deliveryPlace"
    );

    const deliveryPartyTemplate = renderTextTemplate(
      formData.deliveryParty,
      "delivery_party",
      "Delivery Party",
      "deliveryParty"
    );

    const missingValueTemplate = renderTextTemplate(
      formData.missingValue,
      "missing_value",
      "Data to be filled out",
      "missingValue"
    );

    const toTemplate = renderTextTemplate(
      formData.ownersManagers,
      "owners_managers",
      "Disponent owner",
      "ownersManagers"
    );

    const dateTemplate = renderTextTemplate(
      formData.currentDate,
      "current_Date",
      "Today's date",
      "currentDate"
    );

    const LabelValueRow = ({ label, value }) => (
      <Grid2 container spacing={2}>
        <Grid2 xs={1}>
          <Typography variant="body1">{label}:</Typography>
        </Grid2>
        <Grid2 xs={11}>
          <Typography variant="body1">
            <Box component="span" sx={{ ...strongStyle }}>
              {value}
            </Box>
          </Typography>
        </Grid2>
      </Grid2>
    );
    LabelValueRow.propTypes = {
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    };

    const HeaderSection = ({ type }) => (
      <>
        <Typography
          variant="body1"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            To:
            <Box component="strong" sx={{ ml: 4, ...strongStyle }}>
              {toTemplate}
            </Box>
          </Box>
          {type !== "NAVIGATOR_LOI_BLENDING_CARGO" && (
            <Box>
              Date:
              <Box component="strong" sx={{ ml: 1, ...strongStyle }}>
                {dateTemplate}
              </Box>
            </Box>
          )}
        </Typography>

        <>
          <Typography sx={{ ml: 7 }} variant="body1">
            The Owners of the&nbsp;{vesselTemplate}
          </Typography>
          <Typography sx={{ ml: 7 }} variant="body1">
            {addressTemplate}
          </Typography>
        </>

        <Typography sx={{ mt: 2 }} variant="body1">
          Dear Sirs,
        </Typography>
      </>
    );
    HeaderSection.propTypes = {
      type: PropTypes.string.isRequired,
    };

    const renderGrid = (gridData, marginLeft) => (
      <Grid2 container direction="column" sx={{ mt: 2, ml: marginLeft }}>
        {gridData.map(({ label, value }, index) => (
          <Grid2 key={index}>
            <LabelValueRow label={label} value={value} />
          </Grid2>
        ))}
      </Grid2>
    );

    const renderHeader = () => {
      const headerCommonProps = {
        type: staticData.loiTemplate.type,
      };

      const gridDataNavigatorVoyage = [
        {
          label: "Ship",
          value: vesselTemplate,
        },
        {
          label: "Voyage",
          value: (
            <>
              {portLoadingTemplate} to {portDischargeTemplate}
            </>
          ),
        },
        { label: "Cargo", value: cargoDescriptionTemplate },
        {
          label: "Bill of Lading",
          value: (
            <>
              {billOfLadingIdentificationNumberTemplate}{" "}
              {billOfLadingPlaceAndDateOfIssueTemplate}
            </>
          ),
        },
      ];

      const gridDataNavigatorVoyageSplit = [
        { label: "Ship", value: vesselTemplate },
        { label: "Load port", value: portLoadingTemplate },
        { label: "Delivery port", value: portDischargeTemplate },
        { label: "Cargo", value: cargoDescriptionTemplate },
        { label: "Cargo quantity", value: missingValueTemplate },
        {
          label: "Bill of Lading",
          value: (
            <>
              {billOfLadingIdentificationNumberTemplate}{" "}
              {billOfLadingPlaceAndDateOfIssueTemplate}
            </>
          ),
        },
        {
          label: "Charter Party",
          value: `${staticData.owners || getValue("owners_managers")} ${
            staticData.currentDate
          } `,
        },
      ];

      const gridDataDefault = [
        { label: "Vessel", value: vesselTemplate },
        { label: "Port of Loading", value: portLoadingTemplate },
        { label: "Port of Discharge", value: portDischargeTemplate },
        { label: "Cargo", value: cargoDescriptionTemplate },
        {
          label: "Bill of Lading",
          value: (
            <>
              {billOfLadingIdentificationNumberTemplate}{" "}
              {billOfLadingPlaceAndDateOfIssueTemplate}
            </>
          ),
        },
      ];

      switch (staticData.loiTemplate.type) {
        case "NAVIGATOR_LOI_NO_BILL":
        case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
          return (
            <>
              <HeaderSection {...headerCommonProps} />
              {renderGrid([...gridDataNavigatorVoyage])}
            </>
          );
        case "NAVIGATOR_LOI_SUBSTITUTE_BL":
          return (
            <>
              <HeaderSection {...headerCommonProps} />
              {renderGrid(gridDataNavigatorVoyageSplit)}
            </>
          );
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <>
              <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                <Box component="strong" sx={{ ...strongStyle }}>
                  {staticData.currentDate}
                </Box>
              </Typography>

              <HeaderSection {...headerCommonProps} />
              {renderGrid([...gridDataNavigatorVoyage])}
            </>
          );
        default:
          return (
            <>
              <Typography variant="body1">
                To:
                <Box component="strong" sx={{ ml: 4, ...strongStyle }}>
                  {staticData.owners
                    ? staticData.owners
                    : getValue("owners_managers")}
                </Box>
              </Typography>

              <Typography sx={{ ml: 7 }} variant="body1">
                and/or the Owners and/or Managers of the&nbsp;
                {vesselTemplate}
              </Typography>

              <Typography variant="body1" sx={{ mt: 2 }}>
                Date:
                <Box component="strong" sx={{ ml: 2, ...strongStyle }}>
                  {staticData.currentDate}
                </Box>
              </Typography>

              <Typography sx={{ mt: 2 }} variant="body1">
                Dear Sirs,
              </Typography>

              {renderGrid(gridDataDefault, 7)}
            </>
          );
      }
    };

    // Function to render cargo description based on template type
    const renderCargoDescription = () => {
      if (!staticData.loiTemplate) return null;
      switch (staticData.loiTemplate.type) {
        case "DEFAULT_LOI_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by&nbsp;
              {shipperTemplate}
              &nbsp;and consigned to&nbsp;
              {consigneeTemplate}
              &nbsp;for delivery at the Port of Discharge but the Bill(s) of
              Lading is (are) not currently available to be presented.
            </Typography>
          );

        case "DEFAULT_LOI_OTHER_PORT":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by&nbsp;
              {shipperTemplate}
              &nbsp;and consigned to&nbsp;
              {consigneeTemplate}
              &nbsp;for delivery at the Port of Discharge but we,{" "}
              {formData.requestorParty}&nbsp; hereby request you to order the
              Vessel to proceed to and deliver the said cargo at&nbsp;
              {deliveryPlaceTemplate}
              &nbsp;against production of at least one original Bill(s) of
              Lading.
            </Typography>
          );

        case "DEFAULT_LOI_NO_BILL_OTHER_PORT":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by&nbsp;
              {shipperTemplate}
              &nbsp;and consigned to&nbsp;
              {consigneeTemplate}
              &nbsp;for delivery at the Port of Discharge but we,{" "}
              {formData.requestorParty}&nbsp; hereby request you to order the
              Vessel to proceed to and deliver the said cargo at&nbsp;
              {deliveryPlaceTemplate}.
            </Typography>
          );
        case "NAVIGATOR_LOI_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by{" "}
              {shipperTemplate} and consigned to {consigneeTemplate} for
              delivery at the port of {portDischargeTemplate} but the bill of
              lading has not arrived and we, {formData.requestorParty}, hereby
              request you to order the Vessel to proceed to and deliver the said
              cargo to {deliveryPartyTemplate} or to such party as you believe
              to be or to represent {deliveryPartyTemplate} or to be acting on
              behalf of {deliveryPartyTemplate} at {deliveryPlaceTemplate}{" "}
              without production of the original bill of lading.
            </Typography>
          );
        case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by{" "}
              {shipperTemplate} and consigned to {consigneeTemplate} for
              delivery at the port of {portDischargeTemplate} but we,{" "}
              {formData.requestorParty}, hereby request you to order the vessel
              to proceed to and deliver the said cargo at{" "}
              {deliveryPlaceTemplate} to {deliveryPartyTemplate} or to such
              party as you believe to be or to represent {deliveryPartyTemplate}{" "}
              or to be acting on behalf of {deliveryPartyTemplate} without
              production of the original bill of lading.
            </Typography>
          );
        case "NAVIGATOR_LOI_SUBSTITUTE_BL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above ship by {shipperTemplate}
              , and consigned to {consigneeTemplate} for delivery at{" "}
              {portDischargeTemplate} pursuant to the above bill of lading
              issued in a set of three (the “First Bills of Lading”). We,{" "}
              {missingValueTemplate} Charterers under the above Charter Party,
              hereby (i) confirm that upon signing this letter of indemnity we
              shall procure and, as soon as possible thereafter, provide to you
              all of the originals of the First Bills of Lading and (ii) request
              that following receipt by you or your appointed agent of all of
              the originals of the First Bills of Lading you or your appointed
              agent shall mark them “null and void” and, thereupon, shall issue
              and release a further bill of lading in a set of three (the
              “Substitute Bills of Lading”) for the same cargo in the wording
              attached hereto (attach copy of intended switch bill of lading);
            </Typography>
          );
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by{" "}
              {shipperTemplate} and consigned to {consigneeTemplate} for
              delivery at the port of {portDischargeTemplate}.
            </Typography>
          );
        default:
          return null;
      }
    };

    // Function to render indemnity request based on template type
    const renderIndemnityRequest = () => {
      if (!staticData.loiTemplate) return null;
      switch (staticData.loiTemplate.type) {
        case "DEFAULT_LOI_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              We, {formData.requestorParty} hereby represent and undertake
              that&nbsp;
              {deliveryPartyTemplate}
              &nbsp;is the party lawfully entitled to delivery of the said Cargo
              and request you to deliver the said Cargo to&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to such party as you believe to be or to represent&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to be acting on behalf of&nbsp;
              {deliveryPartyTemplate}
              &nbsp;at&nbsp;
              {deliveryPlaceTemplate}
              &nbsp;without production of the original Bill(s) of Lading.&nbsp;
            </Typography>
          );
        case "DEFAULT_LOI_NO_BILL_OTHER_PORT":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Further, as the Bill(s) of Lading is (are) not currently available
              to be presented, we also hereby represent and undertake that&nbsp;
              {deliveryPartyTemplate}
              &nbsp;is the party lawfully entitled to delivery of the said Cargo
              and request you to deliver the said Cargo to&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to such party as you believe to be or to represent&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to be acting on behalf of&nbsp;
              {deliveryPartyTemplate}
              &nbsp;at&nbsp;
              {deliveryPlaceTemplate}
              &nbsp;without production of the original Bill(s) of Lading.&nbsp;
            </Typography>
          );
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              We, {formData.requestorParty} hereby request you to proceed to{" "}
              {portLoadingTemplate}, and commingle / blend{" "}
              {cargoDescriptionTemplate} with the cargo described above and
              presently on board CT# {missingValueTemplate}, for discharge at{" "}
              {portDischargeTemplate}.
            </Typography>
          );
        default:
          return null;
      }
    };

    // Function to render indemnity agreement text for template
    const renderIndemnityAgreementText = () => {
      if (!template) return null;
      let indemnityList = template.indemnityAgreement;
      return (
        <List sx={{ listStyle: "none", marginLeft: "-1rem" }}>
          {indemnityList.map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <ListItemText primary={item} />

              {/* If the sublist is not empty, render it */}
              {index === 2 && template.indemnitySublist.length > 0 && (
                <List sx={{ paddingLeft: 4 }}>
                  {template.indemnitySublist.map((subItem, subIndex) => (
                    <ListItem key={subIndex} sx={{ display: "list-item" }}>
                      <ListItemText primary={subItem} />
                    </ListItem>
                  ))}
                </List>
              )}
            </ListItem>
          ))}
        </List>
      );
    };

    // Function to render indemnity terms for template
    const renderIndemnityTerms = () => {
      if (!template) return null;
      return (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {template.indemnityTerms}
        </Typography>
      );
    };

    // Function to render signature text for template
    const renderSignatureText = () => {
      if (!template) return null;
      switch (staticData.loiTemplate.type) {
        case "NAVIGATOR_LOI_NO_BILL":
        case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Yours faithfully
              </Typography>
              <Typography variant="body1">For and on behalf of</Typography>
              <Typography variant="body1">
                [insert name of Requestor]
              </Typography>
              <Typography variant="body1">The Requestor</Typography>
              <Typography sx={{ marginTop: 6 }} variant="body1">
                …………………………………
              </Typography>
              <Typography variant="body1">Signature </Typography>
            </>
          );
        case "NAVIGATOR_LOI_SUBSTITUTE_BL":
          return (
            <>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Yours faithfully
              </Typography>
              <Typography variant="body1">For and on behalf of</Typography>
              <Typography variant="body1">
                [insert name of Charterers]
              </Typography>
              <Typography sx={{ marginTop: 6 }} variant="body1">
                …………………………………
              </Typography>
              <Typography variant="body1">Signature </Typography>
            </>
          );
        default:
          return (
            <>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Yours faithfully
              </Typography>
              <Typography variant="body1">For and on behalf of</Typography>
              <Typography variant="body1">
                <strong style={strongStyle}>
                  {staticData.requestorCompany}
                </strong>
              </Typography>
              <Typography variant="body1">The Requestor</Typography>
              <Typography variant="body1">Signature …………………………………</Typography>
              <Typography variant="body1">
                Name:{" "}
                <strong style={strongStyle}>{staticData.requestorName}</strong>
              </Typography>
              <Typography variant="body1">
                Title:{" "}
                <strong style={strongStyle}>
                  {staticData.requestorDesignation}
                </strong>
              </Typography>
            </>
          );
      }
    };

    const generationCard = (
      <GenerationCard
        title={`LOI Preview`}
        multiple={multiple.isMultipleGeneration}
        handleToggleExport={multiple.handleToggleExport}
        toggleEdit={toggleEdit}
        id={details.doc_id}
      >
        <Box sx={contentStyle} id={`text-content-${details.doc_id}`}>
          {renderHeader()}
          <Divider
            sx={{ marginY: 2, background: theme.palette.primary.blue3 }}
          />
          {/* Rendered based on Loi Template Type */}
          {renderCargoDescription()}
          {renderIndemnityRequest()}
          {renderIndemnityTerms()}
          {renderIndemnityAgreementText()}
          {renderSignatureText()}
        </Box>
        <ExportButtons
          actions={{
            handleCopy: () => handleCopyToClipboard(),
            handleExportPDF: () => handleSaveAsPDFLOI(),
            handleExportDocx: () => handleSaveAsWordLOI(),
          }}
          validation={false}
        />
        <Feedback requestId={requestId} type={"LOI2LOI"} />
      </GenerationCard>
    );

    return (
      <PreviewWrapper generationCard={generationCard} file={staticData.file}>
        <ShipsterCard level={0} title="Source Preview">
          <PDFViewer file={staticData.file} />
        </ShipsterCard>
      </PreviewWrapper>
    );
  }
);

Indemnity.displayName = "Indemnity";
Indemnity.propTypes = {
  details: PropTypes.object.isRequired,
  staticData: PropTypes.shape({
    currentDate: PropTypes.string.isRequired,
    requestorName: PropTypes.string,
    requestorCompany: PropTypes.string,
    requestorDesignation: PropTypes.string,
    owners: PropTypes.string,
    letterheadImage: PropTypes.string,
    loiTemplate: PropTypes.object,
    address: PropTypes.string,
    file: PropTypes.object,
  }).isRequired,
  requestId: PropTypes.string.isRequired,
  multiple: PropTypes.shape({
    isMultipleGeneration: PropTypes.bool.isRequired,
    handleToggleExport: PropTypes.func.isRequired,
  }),
};

export default Indemnity;
