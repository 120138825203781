import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
  Alert,
  Grid2,
  CircularProgress,
} from "@mui/material";
import {
  compareMatesReceipts,
  extractMRandMatch,
} from "../../services/mrService";
import MatesReceiptsResult from "./MatesReceiptsResult";
import Feedback from "../../components/common/Feedback";
import TwoDocumentUpload from "../../components/common/TwoDocumentUpload";
import DocumentUploader from "../../components/common/DocumentUploader";
import MultipleMRBLResult from "./MultipleMRBLResult";
import { getNumberOfPages } from "../../helpers";
import { UserContext } from "../../context/UserContext";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import Step1Buttons from "../../components/common/Step1Buttons";
import MatesReceiptsGeneration from "./MatesReceiptsGeneration";
import GenerationCard from "../../components/generation/GenerationCard";
import CloseFooter from "../../components/common/CloseFooter";
import ExportAllButtons from "../../components/generation/ExportAllButtons";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const MatesReceiptsComparison = () => {
  const [filesSection1, setFilesSection1] = useState(null);
  const [filesSection2, setFilesSection2] = useState(null);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [useOCR, setUseOCR] = useState(true);
  const [useVision, setUseVision] = useState(true);
  const [useContentCache, setUseContentCache] = useState(true);
  const [useComparisonCache, setUseComparisonCache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [includedComparisons, setIncludedComparisons] = useState([]);
  const [originalPdfPageCount, setOriginalPdfPageCount] = useState(1); // Number of pages in original PDF
  const [updatedPdfPageCount, setUpdatedPdfPageCount] = useState(1); // Number of pages in updated PDF
  const { user } = useContext(UserContext);
  const steps = ["Upload & Compare", "Results & Generate", "LOI"];
  const [currentStep, setCurrentStep] = useState(0);
  const [exportToggles, setExportToggles] = useState({});

  const childRefs = useRef([]);

  const handleToggleExport = (index, value) => {
    setExportToggles((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleExportAllDocx = () => {
    childRefs.current.forEach((ref, index) => {
      if (exportToggles[index] && ref && ref.handleExportDocx) {
        ref.handleExportDocx();
      }
    });
  };

  const handleExportAllPDF = () => {
    childRefs.current.forEach((ref, index) => {
      if (exportToggles[index] && ref && ref.handleExportPDF) {
        ref.handleExportPDF();
      }
    });
  };

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  // Call getNumberOfPages when PDFs are selected and set these counts accordingly
  useEffect(() => {
    if (filesSection1) {
      getNumberOfPages(filesSection1).then((pageCount) =>
        setOriginalPdfPageCount(pageCount)
      );
    }
    if (filesSection2) {
      getNumberOfPages(filesSection2).then((pageCount) =>
        setUpdatedPdfPageCount(pageCount)
      );
    }
  }, [filesSection1, filesSection2]);

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const transformResult = (result) => {
    const { comparison_result: comparison } = result;
    const doc1 = comparison.original_MR;
    const doc2 = comparison.updated_MR;
    const comparisonData = comparison.mr_comparison;
    const request_id = result.request_id;

    return { doc1, doc2, comparisonData, request_id };
  };

  const handleMultipleMRStepChange = (step, includedComparisons) => {
    console.log("Included Comparisons:", includedComparisons);
    setIncludedComparisons(includedComparisons);
    setCurrentStep(step);
  };

  const handleCompare = async () => {
    if (!filesSection1 || !filesSection2) {
      alert("Please upload a file in both sections before comparing.");
      return;
    }

    setLoading(true);
    setError(null); // Clear any previous errors
    setResult(null); // Clear previous result
    if (originalPdfPageCount > 1 && updatedPdfPageCount > 1) {
      try {
        const matches = await extractMRandMatch(filesSection1, filesSection2, {
          useOCR,
          useVision,
          useComparisonCache,
          organisationId: getOrganisationFromUser(user),
        });
        setResult(matches);
        setCurrentStep(1);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const comparisonResult = await compareMatesReceipts(
          filesSection1,
          filesSection2,
          {
            useOCR,
            useVision,
            useContentCache,
            useComparisonCache,
            organisationId: getOrganisationFromUser(user),
          }
        );
        setResult(comparisonResult);
        setCurrentStep(1);
        console.log(comparisonResult);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box p={3}>
      <ToolHeader
        title={"MRs <-> MRs -> LOI"}
        subtext={"Compare cargo from two Mate Receipts and generate LOI"}
      ></ToolHeader>
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <TwoDocumentUpload>
            <Grid2 size={6}>
              <DocumentUploader
                files={filesSection1}
                setFiles={setFilesSection1}
                title="Upload first Mates Receipt PDF"
                setResult={setResult}
                allowedFileTypes={["pdf", "docx"]}
                singleFile={true}
              />
            </Grid2>

            <Grid2 size={6}>
              <DocumentUploader
                files={filesSection2}
                setFiles={setFilesSection2}
                title="Upload second Mates Receipt PDF"
                setResult={setResult}
                allowedFileTypes={["pdf", "docx"]}
                singleFile={true}
              />
            </Grid2>
          </TwoDocumentUpload>

          {/* Advanced Options Toggle */}
          {user.isAdmin && (
            <>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleAdvancedOptionsClick}
                sx={{ mt: 3 }}
              >
                {showAdvancedOptions
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
              </Button>

              {/* Advanced Options Section */}
              <Collapse in={showAdvancedOptions}>
                <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useOCR}
                        onChange={(e) => setUseOCR(e.target.checked)}
                      />
                    }
                    label="Use OCR"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useVision}
                        onChange={(e) => setUseVision(e.target.checked)}
                      />
                    }
                    label="Use Vision"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useContentCache}
                        onChange={(e) => setUseContentCache(e.target.checked)}
                      />
                    }
                    label="Use Content Cache"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useComparisonCache}
                        onChange={(e) =>
                          setUseComparisonCache(e.target.checked)
                        }
                      />
                    }
                    label="Use Comparison Cache"
                  />
                </Box>
              </Collapse>
            </>
          )}

          {/* Compare Button */}
          <Step1Buttons
            title={"Compare Mates Receipts"}
            validation={
              filesSection1?.length === 0 ||
              filesSection2?.length === 0 ||
              loading
            }
            handleMoveNextStep={handleCompare}
          />

          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      {result && currentStep === 1 && (
        <>
          {originalPdfPageCount === 1 && updatedPdfPageCount === 1 && (
            <MatesReceiptsResult
              response={transformResult(result)}
              titles={["Original Mates Receipt", "Updated Mates Receipt"]}
              handleStepChange={setCurrentStep}
            />
          )}
          {originalPdfPageCount > 1 && updatedPdfPageCount > 1 && (
            <MultipleMRBLResult
              response={result}
              comparedWithBl={false}
              handleStepChange={handleMultipleMRStepChange}
            />
          )}
          <Feedback requestId={result.request_id} type={"MR"} />
        </>
      )}
      {/* This doesn't work for multiple MR to MR because the initial result is not a comparison */}
      {result && currentStep === 2 && (
        <>
          {originalPdfPageCount === 1 && updatedPdfPageCount === 1 && (
            <>
              <MatesReceiptsGeneration
                doc1={result.comparison_result.original_MR}
                doc2={result.comparison_result.updated_MR}
                comparison={result.comparison_result.mr_comparison}
                requestId={result.request_id}
                file={[filesSection1, filesSection2]}
              />
              <Feedback requestId={result.request_id} type={"MR"} />
            </>
          )}
          {originalPdfPageCount > 1 && updatedPdfPageCount > 1 && (
            <>
              {includedComparisons.map((comparison, index) => (
                <Box key={`mates generation ${index}`}>
                  <GenerationCard
                    title={`LOI for MR Amendment ${comparison.comparison_result.updated_MR.short_name}`}
                    handleToggleExport={handleToggleExport}
                    multiple={true}
                    toggleEdit={() => {}}
                    id={index}
                  >
                    <MatesReceiptsGeneration
                      ref={(el) => (childRefs.current[index] = el)}
                      doc1={comparison.comparison_result.original_MR}
                      doc2={comparison.comparison_result.updated_MR}
                      comparison={comparison.comparison_result.mr_comparison}
                      requestId={comparison.request_id}
                      file={[filesSection1, filesSection2]}
                    />
                    <Feedback requestId={comparison.request_id} type={"MR"} />
                  </GenerationCard>
                </Box>
              ))}
              <ExportAllButtons
                actions={{
                  handleExportAllPDF: handleExportAllPDF,
                  handleExportAllDocx: handleExportAllDocx,
                }}
                exportToggles={exportToggles}
              />
              <CloseFooter />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default MatesReceiptsComparison;
