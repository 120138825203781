import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";
import { getFilledChristianiaVITemplate } from "../../templates/ChristianiaVITemplate";
import { getFilledCenturionVITemplate } from "../../templates/CenturionVITemplate";
import { getFilledFalconVITemplate } from "../../templates/FalconVITemplate";
import EditableFieldTemplate from "../../components/generation/EditableFieldTemplate";
import { sendFieldFeedback } from "../../services/feedbackService";
import { handleSaveAsWord } from "../../services/viDocxService";
import { handleSaveAsPDF } from "../../services/viPdfService";
import ExportButtons from "../../components/generation/ExportButtons";
import GenerationCard from "../../components/generation/GenerationCard";
import PDFViewer from "../../components/common/PDFViewer";
import ShipsterCard from "../../components/common/ShipsterCard";
import PreviewWrapper from "../../components/generation/PreviewWrapper";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const Recap2InstructionsResult = ({ response, file }) => {
  const { user } = useContext(UserContext);
  const [result, setResult] = useState(null);
  const [trackedChanges, setTrackedChanges] = useState({});
  const [isEditable, setIsEditable] = useState(true);

  // Initialize formData to a default empty object
  const [formData, setFormData] = useState({});

  // useEffect to set result and formData
  useEffect(() => {
    if (response) {
      setResult(response);

      // Safely update formData only when response is valid
      const { fixure_recap_details } = response || {};
      if (fixure_recap_details) {
        const {
          cp_date: cpDateField,
          charterer: chartererField,
          laycan: laycanField,
          speed: speedField,
          cleaning: cleaningField,
          heating: heatingField,
          cargo: cargoField,
          load_port_agents: loadingAgentsField,
          load_port_agent_email: loadAgentEmailField,
          discharge_port_agents: dischargeAgentsField,
          discharge_port_agent_email: dischargeAgentEmailField,
          port_of_loading: portOfLoadingField,
          port_of_discharge: portOfDischargeField,
          previous_port: previousPortField,
          brokers_email: brokersEmailField,
          brokers: brokersField,
          discharge_rate: dischargeRateField,
          load_rate: loadRateField,
          segregation: segregationField,
          vessel: vesselField,
        } = fixure_recap_details;

        const missingValueField = {
          value: "Input Needed",
          context: null,
          confidence: "LOW",
        };

        const getValue = (field) => field?.value || "MISSING";

        setFormData({
          cpDate: getValue(cpDateField),
          charterer: getValue(chartererField),
          laycan: getValue(laycanField),
          speed: getValue(speedField),
          cleaning: getValue(cleaningField),
          heating: getValue(heatingField),
          cargo: getValue(cargoField),
          loadingAgents: getValue(loadingAgentsField),
          loadAgentEmail: getValue(loadAgentEmailField),
          dischargeAgents: getValue(dischargeAgentsField),
          dischargeAgentEmail: getValue(dischargeAgentEmailField),
          portOfLoading: getValue(portOfLoadingField),
          portOfDischarge: getValue(portOfDischargeField),
          previousPort: getValue(previousPortField),
          brokersEmail: getValue(brokersEmailField),
          brokers: getValue(brokersField),
          dischargeRate: getValue(dischargeRateField),
          loadRate: getValue(loadRateField),
          segregation: getValue(segregationField),
          vessel: getValue(vesselField),
          missingValue: getValue(missingValueField),
        });
      }
    }
  }, [response]);

  // Avoid rendering prematurely if result is not yet set
  if (!result) {
    return null;
  }

  const trackChange = (
    field,
    newValue,
    parentField = "fixure_recap_details"
  ) => {
    setTrackedChanges((prevChanges) => {
      const originalValue = result[parentField]?.[field]?.value; // Accessing original value
      if (originalValue !== newValue) {
        return {
          ...prevChanges,
          [field]: { value: newValue },
        };
      } else {
        // eslint-disable-next-line no-unused-vars
        const { [field]: _, ...remainingChanges } = prevChanges;
        return remainingChanges;
      }
    });
  };

  const sendTrackChangesToBackend = async () => {
    try {
      await sendFieldFeedback(
        getOrganisationFromUser(user),
        result.requestId,
        trackedChanges,
        "VI"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const updateFormData = (key, value, parentField = "fixure_recap_details") => {
    setFormData((prevData) => {
      // Track changes before updating formData (no document ID for simple fields)
      trackChange(key, value, parentField);
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  // Function to copy modal content to clipboard
  const handleCopyToClipboard = () => {
    const element = document.getElementById("text-content");
    const textToCopy = element.innerText || element.textContent; // Get only the text content
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
    sendTrackChangesToBackend();
  };

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const renderTextTemplate = (field, contextKey, nullContext, valueName) => {
    const valueDisplay = field === "MISSING" ? `MISSING ${nullContext}` : field;
    return (
      <EditableFieldTemplate
        value={valueDisplay}
        context={response.fixure_recap_details[contextKey]?.context}
        nullContext={nullContext}
        confidence={response.fixure_recap_details[contextKey]?.confidence}
        updateFormData={updateFormData}
        valueName={valueName}
        isEditable={isEditable}
      />
    );
  };

  const cpDateTemplate = renderTextTemplate(
    formData.cpDate,
    "cp_date",
    "CP Date",
    "cpDate"
  );
  const portLoadingTemplate = renderTextTemplate(
    formData.portOfLoading,
    "port_of_loading",
    "Port of Loading",
    "portOfLoading"
  );
  const portDischargeTemplate = renderTextTemplate(
    formData.portOfDischarge,
    "port_of_discharge",
    "Port of Discharge",
    "portOfDischarge"
  );
  const previousPortTemplate = renderTextTemplate(
    formData.previousPort,
    "previous_port",
    "Starting point of vessel",
    "previousPort"
  );
  const chartererTemplate = renderTextTemplate(
    formData.charterer,
    "charterer",
    "Charterer Name",
    "charterer"
  );
  const cargoDescriptionTemplate = renderTextTemplate(
    formData.cargo,
    "cargo",
    "Cargo Description",
    "cargo"
  );
  const laycanTemplate = renderTextTemplate(
    formData.laycan,
    "laycan",
    "Laycan",
    "laycan"
  );
  const speedTemplate = renderTextTemplate(
    formData.speed,
    "speed",
    "Speed",
    "speed"
  );
  const heatingTemplate = renderTextTemplate(
    formData.heating,
    "heating",
    "Heating",
    "heating"
  );
  const cleaningTemplate = renderTextTemplate(
    formData.cleaning,
    "cleaning",
    "Cleaning Details",
    "cleaning"
  );
  const loadingAgentsTemplate = renderTextTemplate(
    formData.loadingAgents,
    "load_port_agents",
    "Load Port Agents",
    "loadingAgents"
  );
  const loadAgentEmailTemplate = renderTextTemplate(
    formData.loadAgentEmail,
    "load_port_agent_email",
    "Load Port Agent Email",
    "loadAgentEmail"
  );
  const dischargeAgentsTemplate = renderTextTemplate(
    formData.dischargeAgents,
    "discharge_port_agents",
    "Discharge Port Agent",
    "dischargeAgents"
  );
  const dischargeAgentEmailTemplate = renderTextTemplate(
    formData.dischargeAgentEmail,
    "discharge_port_agent_email",
    "Discharge Port Agent Email",
    "dischargeAgentEmail"
  );
  const brokersTemplate = renderTextTemplate(
    formData.brokers,
    "brokers",
    "Brokers",
    "brokers"
  );
  const brokersEmailTemplate = renderTextTemplate(
    formData.brokersEmail,
    "brokers_email",
    "Brokers Email",
    "brokersEmail"
  );
  const loadRateTemplate = renderTextTemplate(
    formData.loadRate,
    "discharge_rate",
    "Load Rate",
    "loadRate"
  );
  const dischargeRateTemplate = renderTextTemplate(
    formData.dischargeRate,
    "discharge_rate",
    "Discharge Rate",
    "dischargeRate"
  );
  const vesselTemplate = renderTextTemplate(
    formData.vessel,
    "vessel",
    "Vessel",
    "vessel"
  );
  const missingValueTemplate = renderTextTemplate(
    formData.missingValue,
    "Data to be filled out",
    ""
  );

  const renderGeneratedTemplate = () => {
    if (!user || !result?.fixure_recap_details) return;

    switch (user.orgId) {
      case "christiania":
        return getFilledChristianiaVITemplate({
          formData: formData,
          cpDate: cpDateTemplate,
          charterer: chartererTemplate,
          cargo: cargoDescriptionTemplate,
          laycan: laycanTemplate,
          speed: speedTemplate,
          cleaning: cleaningTemplate,
          heating: heatingTemplate,
          loadingAgents: loadingAgentsTemplate,
          loadAgentEmail: loadAgentEmailTemplate,
          dischargeAgents: dischargeAgentsTemplate,
          dischargeAgentEmail: dischargeAgentEmailTemplate,
          portOfLoading: portLoadingTemplate,
          portOfDischarge: portDischargeTemplate,
          previousPort: previousPortTemplate,
          broker: brokersTemplate,
          brokersEmail: brokersEmailTemplate,
          missingValue: missingValueTemplate,
        });
      case "centurion":
        return getFilledCenturionVITemplate({
          vessel: vesselTemplate,
          cpDate: cpDateTemplate,
          charterer: chartererTemplate,
          cargo: cargoDescriptionTemplate,
          laycan: laycanTemplate,
          speed: speedTemplate,
          loadingAgents: loadingAgentsTemplate,
          loadAgentEmail: loadAgentEmailTemplate,
          loadRate: loadRateTemplate,
          dischargeAgents: dischargeAgentsTemplate,
          dischargeAgentEmail: dischargeAgentEmailTemplate,
          dischargeRate: dischargeRateTemplate,
          portOfLoading: portLoadingTemplate,
          portOfDischarge: portDischargeTemplate,
          brokersEmail: brokersEmailTemplate,
          missingValue: missingValueTemplate,
        });
      case "navigator":
        // Add logic for 'navigator' if needed
        break;
      case "falcon":
        return getFilledFalconVITemplate({
          cargo: cargoDescriptionTemplate,
          cpDate: cpDateTemplate,
          dischargeAgents: dischargeAgentsTemplate,
          dischargeAgentEmail: dischargeAgentEmailTemplate,
          laycan: laycanTemplate,
          loadAgentEmail: loadAgentEmailTemplate,
          loadingAgents: loadingAgentsTemplate,
          portOfDischarge: portDischargeTemplate,
          portOfLoading: portLoadingTemplate,
          speed: speedTemplate,
          missingValue: missingValueTemplate,
        });
      case "internal":
        return getFilledCenturionVITemplate({
          vessel: vesselTemplate,
          cpDate: cpDateTemplate,
          charterer: chartererTemplate,
          cargo: cargoDescriptionTemplate,
          laycan: laycanTemplate,
          speed: speedTemplate,
          loadingAgents: loadingAgentsTemplate,
          loadAgentEmail: loadAgentEmailTemplate,
          loadRate: loadRateTemplate,
          dischargeAgents: dischargeAgentsTemplate,
          dischargeAgentEmail: dischargeAgentEmailTemplate,
          dischargeRate: dischargeRateTemplate,
          portOfLoading: portLoadingTemplate,
          portOfDischarge: portDischargeTemplate,
          brokersEmail: brokersEmailTemplate,
          missingValue: missingValueTemplate,
        });
      default:
        break;
    }
  };

  return (
    <Box>
      <PreviewWrapper
        generationCard={
          <GenerationCard
            title={`Voyage Instructions`}
            multiple={false}
            toggleEdit={toggleEdit}
            id={response.request_id}
            handleToggleExport={() => {}}
          >
            {renderGeneratedTemplate()}
          </GenerationCard>
        }
        file={file}
      >
        <ShipsterCard level={0} title="Source Preview">
          <PDFViewer file={file} />
        </ShipsterCard>
      </PreviewWrapper>
      <ExportButtons
        actions={{
          handleCopy: () => {
            handleCopyToClipboard();
          },
          handleExportPDF: () => {
            handleSaveAsPDF(formData, user.orgId);
          },
          handleExportDocx: () => {
            handleSaveAsWord(formData, user.orgId);
          },
        }}
        validation={false}
      />
    </Box>
  );
};

Recap2InstructionsResult.propTypes = {
  response: PropTypes.object.isRequired,
  file: PropTypes.string.isRequired,
};

export default Recap2InstructionsResult;
