import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
  useEffect,
} from "react";
import { saveAs } from "file-saver";
import {
  Box,
  Divider,
  Typography,
  Switch,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import {
  letterheadOptions,
  requesterCompanyOptions,
} from "../../config/loginDetails";
import { toSnakeCase, toTitleCase } from "../../helpers";
import { handleExportToWordBlobMRLOI } from "../../services/loiDocxService";
import { handleSaveMRLOIAsPDF } from "../../services/loiPdfService";
import {
  sendFieldFeedback,
  sendToggleFeedback,
} from "../../services/feedbackService";
import ShipsterCard from "../../components/common/ShipsterCard";
import ExportButtons from "../../components/generation/ExportButtons";
import PDFViewer from "../../components/common/PDFViewer";
import PreviewWrapper from "../../components/generation/PreviewWrapper";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const checkForSignificance = (significance) =>
  significance === "MATERIAL_VARIANCE";

export const checkForChanged = (significance) =>
  ["MATERIAL_VARIANCE", "INSIGNIFICANT_VARIANCE"].includes(significance);

const MatesReceiptsGeneration = forwardRef(
  ({ doc1, doc2, comparison, requestId, file }, ref) => {
    const theme = useTheme();
    const { user } = useContext(UserContext);
    const currentDate = new Date().toLocaleDateString();
    const availableLetterheadOptions = letterheadOptions.filter(
      (option) => option.organisation === user.orgId
    );
    const availableRequesterOptions = requesterCompanyOptions.filter(
      (option) => option.organisation === user.orgId
    );
    const [trackedChanges, setTrackedChanges] = useState({});
    // State to track enabled/disabled status of each field
    const [fieldStates, setFieldStates] = useState(
      Object.fromEntries(
        Object.keys(comparison).map((key) => [
          key,
          checkForSignificance(comparison[key]["significant_variance"]),
        ])
      )
    );

    // Initialize state based on the length of MRcomparison.hold_changes
    const [holdFieldStates, setHoldFieldStates] = useState(
      comparison.hold_changes.map((_holdChange, index) => ({
        content_description: checkForSignificance(
          comparison.hold_changes[index]["content_description_variance"]
        ),
        quantity_description: checkForSignificance(
          comparison.hold_changes[index]["quantity_description_variance"]
        ),
        quantity_metrics: true,
        hold_id: true,
      }))
    );

    const [formData, setFormData] = useState({
      currentDate: currentDate,
      vessel: doc2.vessel,
      owners: "",
      portLoading: doc2.port_of_loading,
      portDischarge: doc2.port_of_discharge,
      shipper: doc2.shipper,
      requestorName: "",
      address: availableRequesterOptions[0]?.address,
      letterhead: availableLetterheadOptions[0]?.path,
      changedFields: {},
      changedHoldFields: [],
      title: "",
    });

    // Update `changedFields` and `changedHoldFields` dynamically
    useEffect(() => {
      setFormData((prev) => {
        // Preserve existing changedFields entries
        const updatedChangedFields = Object.entries(comparison)
          .filter(([key]) => fieldStates[key])
          .reduce((acc, [key, value]) => {
            const cleanKey = key.replace("_change", "");
            if (prev.changedFields[cleanKey]) {
              // Use existing values if present
              acc[cleanKey] = prev.changedFields[cleanKey];
            } else {
              // Add new entry based on doc1 and doc2
              acc[cleanKey] = {
                field: value.field,
                originalValue: doc1[toSnakeCase(value.field)],
                updatedValue: doc2[toSnakeCase(value.field)],
              };
            }
            return acc;
          }, {});

        // Preserve existing changedHoldFields entries
        const updatedChangedHoldFields = comparison.hold_changes
          .map((holdChange, index) => ({
            holdId: holdChange.hold,
            fields: ["content_description", "quantity_description"]
              .filter((field) => holdFieldStates[index][field])
              .map((field) => {
                const existingHoldField = prev.changedHoldFields.find(
                  (item) =>
                    item.holdId === holdChange.hold &&
                    item.fields.some((f) => f.fieldName === field)
                );
                if (existingHoldField) {
                  // Use existing values if present
                  return existingHoldField.fields.find(
                    (f) => f.fieldName === field
                  );
                } else {
                  // Add new entry based on doc1 and doc2
                  return {
                    fieldName: field,
                    originalValue: doc1.hold_details.find(
                      (h) => h.hold === holdChange.hold
                    )?.[field],
                    updatedValue: doc2.hold_details.find(
                      (h) => h.hold === holdChange.hold
                    )?.[field],
                  };
                }
              }),
          }))
          .filter((hold) => hold.fields.length > 0);

        return {
          ...prev,
          changedFields: updatedChangedFields,
          changedHoldFields: updatedChangedHoldFields,
        };
      });
    }, [fieldStates, holdFieldStates, comparison, doc1, doc2]);

    useImperativeHandle(ref, () => ({
      handleExportDocx,
      handleExportPDF,
    }));

    // Handle switch toggle for hold field states
    const handleHoldFieldSwitchChange = (index, field) => {
      setHoldFieldStates((prevState) =>
        prevState.map((item, i) =>
          i === index ? { ...item, [field]: !item[field] } : item
        )
      );
    };

    // Handle switch toggle for regular fields
    const handleFieldSwitchChange = (key) => {
      setFieldStates((prevStates) => ({
        ...prevStates,
        [key]: !prevStates[key],
      }));
    };

    // Function to add or update tracked changes with document IDs
    const trackChange = (key, field, newValue, documentId = null) => {
      setTrackedChanges((prevChanges) => {
        const originalValue = formData[key];
        // Only add to trackedChanges if the value is actually changed
        if (originalValue !== newValue) {
          return {
            ...prevChanges,
            [key]: { field, value: newValue, documentId },
          };
        } else {
          // Remove from trackedChanges if it reverts back to original
          // eslint-disable-next-line no-unused-vars
          const { [key]: _, ...remainingChanges } = prevChanges;
          return remainingChanges;
        }
      });
    };

    const updateFormData = (key, value, isFieldChange, isHold) => {
      trackChange(key, key, value);
      setFormData((prevData) => {
        const updatedData = { ...prevData };

        if (isFieldChange && !isHold) {
          // Handle changes for main fields
          const [fieldKey, fieldType] = key.split("-");
          if (!updatedData.changedFields) updatedData.changedFields = {};
          if (!updatedData.changedFields[fieldKey]) {
            updatedData.changedFields[fieldKey] = { fieldName: fieldKey };
          }
          updatedData.changedFields[fieldKey][fieldType] = value;
        } else if (isFieldChange && isHold) {
          // Handle hold field changes
          const [holdField, fieldType] = key.split("-");
          if (!updatedData.changedHoldFields)
            updatedData.changedHoldFields = [];
          const holdIndex = updatedData.changedHoldFields[0].fields.findIndex(
            (item) => item.fieldName === holdField
          );

          if (holdIndex >= 0) {
            // Update existing hold field entry
            updatedData.changedHoldFields[0].fields[holdIndex][fieldType] =
              value;
          } else {
            // Add a new hold field entry
            updatedData.changedHoldFields[0].fields.push({
              fieldName: holdField,
              [fieldType]: value,
            });
          }
        } else {
          updatedData[key] = value;
        }
        return updatedData;
      });
    };

    const prepareDataForAPI = (trackedChanges) => {
      const updatedFields = {};

      Object.values(trackedChanges).forEach(({ field, value, documentId }) => {
        updatedFields[field] = {
          value: value,
          documentId: documentId,
        };
      });

      return updatedFields;
    };

    const sendFeedback = async () => {
      const toggles = { ...fieldStates };

      holdFieldStates.forEach((hold, index) => {
        Object.keys(hold).forEach((field) => {
          toggles[`hold_${index}_${field}`] = hold[field];
        });
      });

      try {
        await sendToggleFeedback(
          getOrganisationFromUser(user),
          requestId,
          toggles,
          "MR"
        );
        await sendFieldFeedback(
          getOrganisationFromUser(user),
          requestId,
          prepareDataForAPI(trackedChanges),
          "MR"
        );
      } catch (error) {
        console.error(error);
      }
    };

    const handleCopyToClipboard = (requestId) => {
      // Send track changes for evaluations
      sendFeedback();

      // Get the LOI content element using the unique ID
      const element = document.querySelector(`#loi-content-${requestId}`);

      if (!element) {
        console.error(
          `LOI content element for requestId ${requestId} not found.`
        );
        return;
      }

      // Clone the element to manipulate content safely
      const clonedElement = element.cloneNode(true);

      // Filter toggles: Include only toggles that are turned on
      const toggles = clonedElement.querySelectorAll("[data-toggle-state]");
      toggles.forEach((toggle) => {
        const isChecked = toggle.getAttribute("data-toggle-state") === "true";
        if (!isChecked) {
          toggle.remove();
        }
      });

      // Extract text with proper formatting
      const extractFormattedText = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          return node.textContent;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "BR") {
            return "\n";
          } else if (["DIV", "P", "LI"].includes(node.tagName)) {
            return (
              Array.from(node.childNodes).map(extractFormattedText).join("") +
              "\n"
            );
          } else {
            return Array.from(node.childNodes)
              .map(extractFormattedText)
              .join("");
          }
        }
        return "";
      };

      const formattedText = extractFormattedText(clonedElement).trim();

      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          alert("LOI Copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    };

    const handleExportDocx = async () => {
      try {
        sendFeedback();
        const wordDocBlob = await handleExportToWordBlobMRLOI(formData);
        saveAs(wordDocBlob, `MR_Amendment_LOI.docx`);
      } catch (error) {
        console.error("Error generating Word document:", error);
      }
    };

    const handleExportPDF = async () => {
      try {
        await handleSaveMRLOIAsPDF(formData);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    };

    const renderToggles = () => (
      <Box>
        {Object.entries(comparison).map(([key, change]) =>
          checkForChanged(change.significant_variance) ? (
            <FieldToggle
              key={`${key} toggle`}
              index={key}
              fieldData={{
                fieldName: toTitleCase(change.field),
                originalValue: doc1[toSnakeCase(change.field)],
                updatedValue: doc2[toSnakeCase(change.field)],
              }}
              stateData={{
                states: fieldStates,
                toggleStates: handleFieldSwitchChange,
              }}
              onValueChange={(fieldType, value) =>
                updateFormData(
                  `${key.replace("_change", "")}-${fieldType}`,
                  value,
                  true,
                  false
                )
              }
              styles={{ strongStyle }}
            />
          ) : null
        )}
        {comparison.hold_changes.map((hold, index) => (
          <div key={hold.hold}>
            {["content_description", "quantity_description"].map((field) => {
              if (checkForChanged(hold[`${field}_variance`])) {
                return (
                  <FieldToggle
                    key={`${index}-${field}`}
                    index={index}
                    fieldData={{
                      fieldName: `Hold ${toTitleCase(field)}`,
                      originalValue: doc1.hold_details.find(
                        (h) => h.hold === hold.hold
                      )[field],
                      updatedValue: doc2.hold_details.find(
                        (h) => h.hold === hold.hold
                      )[field],
                    }}
                    stateData={{
                      states: holdFieldStates,
                      toggleStates: handleHoldFieldSwitchChange,
                      field: field,
                    }}
                    onValueChange={(fieldType, value) =>
                      updateFormData(`${field}-${fieldType}`, value, true, true)
                    }
                    styles={{ strongStyle }}
                  />
                );
              }
              return null;
            })}
          </div>
        ))}
      </Box>
    );

    const strongStyle = {
      color: theme.palette.semantic.success,
      fontWeight: "bold",
      textDecoration: "underline",
      textDecorationStyle: "dotted",
    };

    const generationCard = (
      <ShipsterCard title={`LOI Preview`} level={1}>
        <Box id={`loi-content-${requestId}`} data-id={requestId}>
          <Typography variant="body1">
            <strong style={strongStyle}>{currentDate}</strong>
          </Typography>
          <Typography variant="body1">Dear Sirs,</Typography>
          {["owners", "vessel", "portLoading", "portDischarge", "shipper"].map(
            (key) => {
              const labelMapping = {
                portLoading: "Port of Loading",
                portDischarge: "Port of Discharge",
              };

              const label = labelMapping[key] || toTitleCase(key);

              return (
                <Typography key={key} variant="body1">
                  {label}:&nbsp;
                  <Typography
                    component="span"
                    display={"inline"}
                    suppressContentEditableWarning={true}
                    contentEditable={true}
                    onBlur={(e) =>
                      updateFormData(key, e.target.textContent, false, false)
                    }
                  >
                    <strong style={strongStyle}>{formData[key]}</strong>
                  </Typography>
                </Typography>
              );
            }
          )}
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            We hereby request you to amend the Mates Receipt as follows:
          </Typography>
          <Divider
            sx={{ my: 2, backgroundColor: theme.palette.primary.blue3 }}
          />
          <div>
            {renderToggles(
              doc1,
              doc2,
              comparison,
              fieldStates,
              holdFieldStates,
              handleFieldSwitchChange,
              handleHoldFieldSwitchChange
            )}
          </div>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            In consideration of your complying with our above request, we hereby
            agree as follows:
          </Typography>

          <List sx={{ listStyle: "decimal", paddingLeft: 2 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature which you may sustain by reason of making the requested amendment(s)." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="In the event of any proceedings being commenced against you or any of your servants or agents in connection with making the requested amendment(s) as aforesaid, to provide you or them on demand with sufficient funds to defend the same." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="If, in connection with making the requested amendment(s) as aforesaid, the ship or any other ship or property in the same or associated ownership, management or control should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the vessel (whether by virtue of a caveat being entered on the ship's registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not this may be justified." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="The liability of each and every person under this Indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this Indemnity." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="This Indemnity shall be governed by and construed in accordance with English law and each and every person liable under this Indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England." />
            </ListItem>
          </List>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            Yours faithfully,
          </Typography>
          <Typography variant="body1">For and on behalf of</Typography>
          <Typography variant="body1">
            <strong style={strongStyle}>{formData.requestor}</strong>
          </Typography>
          <Typography variant="body1">
            <strong style={strongStyle}>{formData.address}</strong>
          </Typography>
          <Typography variant="body1">The Requestor</Typography>
          <Typography variant="body1">Signature …………………………………</Typography>
          <Typography variant="body1">
            Name: <strong style={strongStyle}>{formData.requestor}</strong>
          </Typography>
          <Typography variant="body1">
            Title: <strong style={strongStyle}>{formData.title}</strong>
          </Typography>
        </Box>
      </ShipsterCard>
    );

    return (
      <Box>
        <ShipsterCard title="Select Options" level={1}>
          <Box>
            <Typography>Choose Letterhead</Typography>
            <Select
              value={formData.letterhead}
              onChange={(e) =>
                updateFormData("letterhead", e.target.value, false, false)
              }
              fullWidth
              variant="outlined"
              sx={{ mt: 1 }}
            >
              {availableLetterheadOptions.map((option) => (
                <MenuItem key={option.label} value={option.path}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={option.path}
                      alt={option.label}
                      style={{ width: 200, height: 50, marginRight: 8 }}
                    />
                    {option.label}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            {[
              {
                id: "disponent-owners",
                label: "Disponent Owners",
                field: "owners",
              },
              { id: "requestor", label: "Requestor", field: "requestor" },
              {
                id: "requestor-address",
                label: "Requestor Address",
                field: "address",
                multiline: true,
              },
              {
                id: "requestor-title",
                label: "Requestor Title",
                field: "title",
              },
            ].map(({ id, label, field, multiline }) => (
              <FormControl
                key={id}
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <OutlinedInput
                  id={id}
                  value={formData[field]}
                  onChange={(e) =>
                    updateFormData(field, e.target.value, false, false)
                  }
                  label={label}
                  multiline={multiline}
                />
              </FormControl>
            ))}
          </Box>
        </ShipsterCard>
        <PreviewWrapper generationCard={generationCard} file={file}>
          <ShipsterCard level={1} title="Source Preview">
            <PDFViewer file={file} />
          </ShipsterCard>
        </PreviewWrapper>
        <ExportButtons
          actions={{
            handleCopy: () => handleCopyToClipboard(requestId),
            handleExportPDF: handleExportPDF,
            handleExportDocx: handleExportDocx,
          }}
          validation={
            Object.keys(formData.changedFields).length === 0 &&
            formData.changedHoldFields.length === 0
          }
        />
      </Box>
    );
  }
);

MatesReceiptsGeneration.displayName = "MatesReceiptsGeneration";
MatesReceiptsGeneration.propTypes = {
  doc1: PropTypes.object.isRequired,
  doc2: PropTypes.object.isRequired,
  comparison: PropTypes.object.isRequired,
  requestId: PropTypes.string.isRequired,
  file: PropTypes.array.isRequired,
};

const FieldToggle = ({
  index,
  fieldData,
  stateData,
  onValueChange,
  styles,
}) => {
  const theme = useTheme();
  const { fieldName, originalValue, updatedValue } = fieldData;
  const { states, toggleStates, field } = stateData;
  const { strongStyle } = styles;

  const isChecked = field ? states[index][field] : states[index];
  const disabled = !isChecked;
  const opacity = disabled ? 0.6 : 1;

  const handleChange = (value, keyType) => {
    if (onValueChange) {
      onValueChange(keyType, value);
    }
  };

  return (
    <Box
      key={fieldName}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 1,
      }}
      data-toggle-state={isChecked}
    >
      <Typography
        component="span"
        display="inline"
        suppressContentEditableWarning
        contentEditable
        onCopy={(e) => {
          e.preventDefault();
          const textToCopy = Array.from(e.currentTarget.childNodes)
            .map((node) =>
              node.nodeType === 3 ? node.nodeValue : node.textContent
            )
            .join(" ");
          navigator.clipboard.writeText(textToCopy);
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={isChecked}
              onChange={() => toggleStates(index, field)}
              sx={{ opacity }}
            />
          }
          label="Change&nbsp;"
        />
        <Typography
          component="span"
          suppressContentEditableWarning
          contentEditable
          onInput={(e) =>
            handleChange(e.currentTarget.textContent, "fieldName")
          }
          sx={{
            opacity,
            color: theme.palette.semantic.success,
            ...strongStyle,
          }}
        >
          {fieldName}
        </Typography>
        &nbsp;from&nbsp;
        <Typography
          component="span"
          suppressContentEditableWarning
          contentEditable
          onInput={(e) =>
            handleChange(e.currentTarget.textContent, "originalValue")
          }
          sx={{
            opacity,
            color: theme.palette.semantic.success,
            ...strongStyle,
          }}
        >
          {originalValue}
        </Typography>
        &nbsp;to&nbsp;
        <Typography
          component="span"
          suppressContentEditableWarning
          contentEditable
          onInput={(e) =>
            handleChange(e.currentTarget.textContent, "updatedValue")
          }
          sx={{
            opacity,
            color: theme.palette.semantic.success,
            ...strongStyle,
          }}
        >
          {updatedValue}
        </Typography>
      </Typography>
    </Box>
  );
};

FieldToggle.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fieldData: PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    originalValue: PropTypes.string.isRequired,
    updatedValue: PropTypes.string.isRequired,
  }).isRequired,
  stateData: PropTypes.shape({
    states: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    toggleStates: PropTypes.func.isRequired,
    field: PropTypes.string,
  }).isRequired,
  onValueChange: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    strongStyle: PropTypes.object,
  }).isRequired,
};

export default MatesReceiptsGeneration;
