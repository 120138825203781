import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Typography,
  Alert,
  CircularProgress,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { generateInstructions } from "../../services/recap2viService";
import { UserContext } from "../../context/UserContext";
import Recap2InstructionsResult from "./Recap2InstructionsResult";
import Feedback from "../../components/common/Feedback";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import Step1Buttons from "../../components/common/Step1Buttons";
import CloseFooter from "../../components/common/CloseFooter";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const Recap2Instructions = () => {
  const [inputText, setInputText] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useContext(UserContext);
  const steps = ["Upload", "Generate Instructions"];
  const [currentStep, setCurrentStep] = useState(0);
  const theme = useTheme();

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  const handleGeneration = async () => {
    if (!inputText.trim()) {
      alert("Please enter fixture recap text");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const result = await generateInstructions(
        inputText,
        getOrganisationFromUser(user)
      );
      setResult(result);
      setCurrentStep(1);
    } catch (err) {
      setError("Error processing fixture recap: " + err.message);
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <ToolHeader
        title={"Recap -> Voyage Instructions"}
        subtext={"Generate Voyage Instructions from a clean fixture recap"}
      ></ToolHeader>
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ mb: 3 }}>
            <Card
              sx={{
                background: theme.palette.background.level1,
                my: 2,
                borderRadius: "20px",
              }}
            >
              <CardContent>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="intro" sx={{ fontWeight: 600, mb: 3 }}>
                    Upload
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  multiline
                  rows={25}
                  variant="outlined"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Paste your recap text here..."
                />
              </CardContent>
            </Card>
          </Box>

          {/* Submit Button */}
          <Step1Buttons
            title={"Generate Instructions"}
            validation={!inputText.length === 0 || loading}
            handleMoveNextStep={handleGeneration}
          ></Step1Buttons>

          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}
      {result && currentStep === 1 && (
        <>
          <Recap2InstructionsResult response={result} file={inputText} />
          <Feedback
            requestId={result.request_id}
            type={"Voyage Instructions"}
          ></Feedback>
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default Recap2Instructions;
