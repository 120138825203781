import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Box,
  Collapse,
  Grid2,
  CircularProgress,
  Card,
  CardContent,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import { loi2loi } from "../../services/loi2loiService";
import { getNumberOfPages, spliceFile } from "../../helpers";
import OneDocumentUpload from "../../components/common/OneDocumentUpload";
import DocumentUploader from "../../components/common/DocumentUploader";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import Step1Buttons from "../../components/common/Step1Buttons";
import { UserContext } from "../../context/UserContext";
import { requesterCompanyOptions } from "../../config/loginDetails";
import CloseFooter from "../../components/common/CloseFooter";
import ExportAllButtons from "../../components/generation/ExportAllButtons";
import Indemnity from "../../components/generation/Indemnity";
import { useTheme } from "@mui/material";
import { loiTemplateChoices } from "../../templates/utils";
import { letterheadOptions, MAX_FILES } from "../../config/loginDetails";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const LOI2LOI = () => {
  const { user } = useContext(UserContext);
  const theme = useTheme();
  // Filter letterhead options based on user organization
  const availableRequesterOptions = requesterCompanyOptions.filter(
    (option) => option.organisation === user.orgId
  );

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [useOCR, setUseOCR] = useState(true);
  const [useVision, setUseVision] = useState(true);
  const [useContentCache, setUseContentCache] = useState(true);
  const [useLOICache, setUseLOICache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [requestorCompany, setRequestorCompany] = useState(
    availableRequesterOptions[0]?.label
  );
  const [requestorAddress] = useState(availableRequesterOptions[0]?.address);
  const [requestorName, setRequestorName] = useState(user.fullName);
  const [requestorDesignation, setRequestorDesignation] = useState("");
  const [owners, setOwners] = useState("ABC Shipping Co., Ltd.");
  const [pageCount, setPageCount] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(pageCount ? pageCount : 1);
  const steps = ["Upload", "Generate LOI"];
  const [currentStep, setCurrentStep] = useState(0);

  // Filter letterhead options based on user organization
  const availableLetterheadOptions = letterheadOptions.filter(
    (option) => option.organisation === user.orgId
  );

  const [selectedLetterhead, setSelectedLetterhead] = useState(null);
  const loiTemplates =
    loiTemplateChoices[user.orgId] || loiTemplateChoices.default;
  const [selectedLoiTemplate, setSelectedLoiTemplate] = useState(
    loiTemplates[0]
  );
  const [exportToggles, setExportToggles] = useState({});

  const childRefs = useRef([]);

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      getNumberOfPages(uploadedFiles[0]).then((pageCount) => {
        setPageCount(pageCount);
        setEndPage(pageCount);
      });
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (!selectedLetterhead) {
      const matchingLetterhead = availableLetterheadOptions.find(
        (option) => option.label === requestorCompany
      );
      setSelectedLetterhead(
        matchingLetterhead
          ? matchingLetterhead.path
          : availableLetterheadOptions[0]?.path
      );
    }
  }, [requestorCompany, selectedLetterhead, availableLetterheadOptions]);

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleGeneration = async () => {
    if (uploadedFiles.length === 0) {
      alert("Please upload a file before generating");
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);

    try {
      let oneEntityPerPage = uploadedFiles.map(() => false);

      let filesToProcess = uploadedFiles;

      // If there is only one file, splice it
      if (uploadedFiles.length === 1) {
        const singleFile = uploadedFiles[0];
        if (singleFile.type === "application/pdf") {
          const splicedFile = await spliceFile(singleFile, startPage, endPage);
          filesToProcess = [splicedFile];
        }
      }

      if (filesToProcess.length > MAX_FILES) {
        alert(`Maximum of ${MAX_FILES} files allowed`);
        setError(`Maximum of ${MAX_FILES} files allowed`);
        return;
      }

      const result = await loi2loi(filesToProcess, {
        useOCR,
        useVision,
        useContentCache,
        useLOICache,
        oneEntityPerPage,
        organisationId: getOrganisationFromUser(user),
      });
      setResult(result);
      setCurrentStep(1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleExportAllDocx = () => {
    Object.entries(result.loi2loi_details).forEach(([, value], index) => {
      const id = value[0].doc_id;
      const ref = childRefs.current[index];
      if (exportToggles[id] && ref && ref.handleSaveAsWordLOI) {
        ref.handleSaveAsWordLOI();
      }
    });
  };

  const handleExportAllPDF = () => {
    Object.entries(result.loi2loi_details).forEach(([, value], index) => {
      const id = value[0].doc_id;
      const ref = childRefs.current[index];
      if (exportToggles[id] && ref && ref.handleSaveAsPDFLOI) {
        ref.handleSaveAsPDFLOI();
      }
    });
  };

  const handleToggleExport = (index, value) => {
    setExportToggles((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  return (
    <Box p={3}>
      <ToolHeader
        title={"LOI -> LOI"}
        subtext={
          "Generate a Letter of Indemnity from another Letter or Indemnity"
        }
      ></ToolHeader>
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          {/* PDF Upload Section */}
          <OneDocumentUpload>
            <DocumentUploader
              files={uploadedFiles}
              setFiles={setUploadedFiles}
              setResult={setResult}
              allowedFileTypes={["pdf", "docx"]}
              singleFile={false}
              title="Upload LOI/s (PDF, Word)"
            />
          </OneDocumentUpload>

          {/* Input text fields */}
          {uploadedFiles.length > 0 && (
            <Card
              sx={{
                background: theme.palette.background.level1,
                borderRadius: "20px",
                my: 2,
              }}
            >
              <CardContent>
                <Box
                  sx={{ gap: "12px", display: "flex", flexDirection: "column" }}
                >
                  <Typography variant="h2">Select Options</Typography>
                  <Grid2 container spacing={2}>
                    {uploadedFiles.length === 1 && (
                      <>
                        <Grid2 size={2}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 500, mb: 1 }}
                          >
                            Start Page
                          </Typography>
                          <TextField
                            type="number"
                            fullWidth
                            value={startPage}
                            onChange={(e) => {
                              const newValue =
                                e.target.value === ""
                                  ? ""
                                  : Math.min(
                                      Math.max(1, Number(e.target.value)),
                                      pageCount
                                    );
                              setStartPage(newValue); // Update state only if input is within range or empty
                            }}
                            slotProps={{
                              input: {
                                min: 1,
                                max: pageCount,
                                onInput: (e) => {
                                  const newValue =
                                    e.target.value === ""
                                      ? ""
                                      : Math.min(
                                          Math.max(1, Number(e.target.value)),
                                          pageCount
                                        );
                                  e.target.value = newValue;
                                },
                              },
                            }}
                            disabled={pageCount === 1}
                          />
                        </Grid2>
                        <Grid2 size={2}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 500, mb: 1 }}
                          >
                            End Page
                          </Typography>
                          <TextField
                            fullWidth
                            type="number"
                            value={endPage}
                            onChange={(e) => {
                              const newValue =
                                e.target.value === ""
                                  ? ""
                                  : Math.min(
                                      Math.max(1, Number(e.target.value)),
                                      pageCount
                                    );
                              setEndPage(newValue);
                            }}
                            slotProps={{
                              input: {
                                min: 1,
                                max: pageCount,
                                onInput: (e) => {
                                  const newValue =
                                    e.target.value === ""
                                      ? ""
                                      : Math.min(
                                          Math.max(1, Number(e.target.value)),
                                          pageCount
                                        );
                                  e.target.value = newValue;
                                },
                              },
                            }}
                            disabled={pageCount === 1}
                          />
                        </Grid2>
                      </>
                    )}
                    <Grid2 size={4}>
                      {/* Only show letterhead selector if there are letterhead options available */}
                      {availableLetterheadOptions.length > 0 && (
                        <>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 500, mb: 1 }}
                          >
                            Choose Letterhead
                          </Typography>
                          <Select
                            value={selectedLetterhead}
                            onChange={(e) =>
                              setSelectedLetterhead(e.target.value)
                            }
                            fullWidth
                            variant="outlined"
                          >
                            {availableLetterheadOptions.map((option) => (
                              <MenuItem key={option.path} value={option.path}>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <img
                                    src={option.path}
                                    alt={option.label}
                                    style={{
                                      width: 200,
                                      height: 50,
                                      marginRight: 8,
                                    }}
                                  />
                                  {option.label}
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Grid2>
                    <Grid2 size={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, mb: 1 }}
                      >
                        Choose Loi Template
                      </Typography>
                      <Select
                        value={selectedLoiTemplate}
                        onChange={(e) => setSelectedLoiTemplate(e.target.value)}
                        fullWidth
                        variant="outlined"
                        renderValue={(value) => value.label}
                      >
                        {loiTemplates.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid2>
                  </Grid2>

                  <Grid2 container spacing={2} sx={{ mt: 3 }}>
                    <Grid2 size={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, mb: 1 }}
                      >
                        To Owners/Managers
                      </Typography>
                      <TextField
                        id="owners"
                        variant="outlined"
                        fullWidth
                        value={owners}
                        onChange={(e) => setOwners(e.target.value)}
                      />
                    </Grid2>
                  </Grid2>

                  <Grid2 container spacing={2} sx={{ mt: 3 }}>
                    <Grid2 size={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, mb: 1 }}
                      >
                        Requestor Name (Company)
                      </Typography>
                      <Select
                        value={requestorCompany}
                        onChange={(e) => setRequestorCompany(e.target.value)}
                        fullWidth
                        variant="outlined"
                      >
                        {availableRequesterOptions.map((option) => (
                          <MenuItem key={option.label} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid2>
                    <Grid2 size={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, mb: 1 }}
                      >
                        Requestor Name (Company)
                      </Typography>
                      <TextField
                        id="requester-name"
                        variant="outlined"
                        fullWidth
                        value={requestorName}
                        onChange={(e) => setRequestorName(e.target.value)}
                      />
                    </Grid2>
                    <Grid2 size={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, mb: 1 }}
                      >
                        Requestor Title
                      </Typography>
                      <TextField
                        id="requester-designation"
                        variant="outlined"
                        fullWidth
                        value={requestorDesignation}
                        onChange={(e) =>
                          setRequestorDesignation(e.target.value)
                        }
                      />
                    </Grid2>
                  </Grid2>

                  {user.isAdmin && (
                    <>
                      {/* Advanced Options Toggle */}
                      <Button
                        variant="outlined"
                        onClick={handleAdvancedOptionsClick}
                        sx={{ mt: 3 }}
                      >
                        {showAdvancedOptions
                          ? "Hide Advanced Options"
                          : "Show Advanced Options"}
                      </Button>

                      {/* Advanced Options Section */}
                      <Collapse in={showAdvancedOptions}>
                        <Box
                          sx={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            mt: 2,
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={useOCR}
                                onChange={(e) => setUseOCR(e.target.checked)}
                              />
                            }
                            label="Use OCR"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={useVision}
                                onChange={(e) => setUseVision(e.target.checked)}
                              />
                            }
                            label="Use Vision"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={useContentCache}
                                onChange={(e) =>
                                  setUseContentCache(e.target.checked)
                                }
                              />
                            }
                            label="Use Content Cache"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={useLOICache}
                                onChange={(e) =>
                                  setUseLOICache(e.target.checked)
                                }
                              />
                            }
                            label="Use LOI Cache"
                          />
                        </Box>
                      </Collapse>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          )}

          {/* Submit Button */}
          <Step1Buttons
            title={"Generate LOI"}
            validation={uploadedFiles?.length === 0 || loading}
            handleMoveNextStep={handleGeneration}
          />

          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      {result && currentStep === 1 && (
        <>
          {Object.entries(result.loi2loi_details).map(([key, value], index) => (
            <Box key={index}>
              <Indemnity
                ref={(el) => {
                  if (el) {
                    childRefs.current[index] = el;
                  }
                }}
                staticData={{
                  currentDate: new Date().toLocaleDateString(),
                  requestorName: requestorName,
                  requestorCompany: requestorCompany,
                  requestorDesignation: requestorDesignation,
                  owners: owners,
                  letterheadImage: selectedLetterhead,
                  loiTemplate: selectedLoiTemplate,
                  address: requestorAddress,
                  file: uploadedFiles[index],
                }}
                details={value[0]}
                requestId={key}
                multiple={{
                  handleToggleExport: handleToggleExport,
                  isMultipleGeneration:
                    Object.keys(result.loi2loi_details || {}).length > 1,
                }}
              />
            </Box>
          ))}
          <ExportAllButtons
            actions={{
              handleExportAllPDF: handleExportAllPDF,
              handleExportAllDocx: handleExportAllDocx,
            }}
            exportToggles={exportToggles}
          />
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default LOI2LOI;
